import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Styles/Desafio.css';
import { toast } from 'react-toastify';
import { FaDumbbell, FaLock } from 'react-icons/fa';
import GatewayPagamento from '../../EfiPay/GatewayPagamento';

export default function Definicao() {
  const [addOrderBump, setAddOrderBump] = useState(false);
  const [amount, setAmount] = useState(10.99);
  const [showIncluded, setShowIncluded] = useState(false);
  const navigate = useNavigate();

  const handleChangeOrderBump = () => {
    const baseAmount = 10.99;
    const extraAmount = 4.99;

    setAddOrderBump(!addOrderBump);
    setAmount(!addOrderBump ? baseAmount + extraAmount : baseAmount);
    if (!addOrderBump) {
      setShowIncluded(true);
    }
  };

  useEffect(() => {
    let timer;
    if (showIncluded) {
      timer = setTimeout(() => {
        setShowIncluded(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [showIncluded]);

  const handlePaymentWaiting = () => {
    toast.info('Pagamento pendente. Por favor, finalize o pagamento via Pix e volte para essa página!');
  };

  const handlePaymentSuccess = () => {
    navigate('/comprarealizada');
  };

  return (
    <div className="relative min-h-screen overflow-hidden">
      <div className="absolute inset-0 bg-gray-100 backdrop-filter backdrop-blur-sm"></div>
      <div className="relative z-10 min-h-screen flex flex-col items-center justify-center p-2 sm:p-4">
        <div className="bg-white rounded-lg shadow-xl overflow-hidden w-full max-w-[95%] sm:max-w-6xl">
          <div className="p-3 sm:p-6">
            <div className="flex flex-col lg:flex-row">
              <div className="lg:w-1/2 lg:pr-4">
                <h3 className="text-xl font-semibold mb-3">Resumo do Pedido</h3>
                <div className="bg-green-50 p-3 sm:p-4 rounded-lg mb-4">
                  <div className="text-green-600 flex justify-between mb-2">
                    <span>Dieta para Definição</span>
                    <span className="font-semibold">R$ 10,99</span>
                  </div>
                  {addOrderBump && (
                    <div className="flex justify-between text-green-600">
                      <span>Treino Personalizado e Pdf</span>
                      <span className="font-semibold">R$ 4,99</span>
                    </div>
                  )}
                  <div className="border-t border-green-300 mt-2 pt-2 flex justify-between font-semibold">
                    <span>Total</span>
                    <span>R$ {amount.toFixed(2)}</span>
                  </div>
                </div>

                <div className="bg-blue-50 p-3 rounded-lg mb-4 transition-all duration-300 ease-in-out">
                  <label className="flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={addOrderBump}
                      onChange={handleChangeOrderBump}
                      className="form-checkbox h-5 w-5 text-blue-600 rounded-md border-blue-300 focus:ring-blue-500"
                    />
                    <span className="ml-2 flex-grow">
                      <span className="text-sm font-semibold text-blue-800">Adicionar Treino Personalizado</span>
                      <span className="text-blue-600 text-xs ml-1">+R$ 4,99</span>
                    </span>
                    <FaDumbbell className="text-blue-500 text-lg" />
                  </label>
                </div>

                {showIncluded && (
                  <div className="bg-green-50 p-3 border-l-4 border-green-500 rounded-lg mb-4 transition-all duration-300 ease-in-out">
                    <div className="flex items-center">
                      <span className="text-green-500 mr-2 text-lg">✔</span>
                      <h2 className="text-sm font-semibold text-green-800">Treino Incluído</h2>
                    </div>
                    <p className="mt-1 text-xs text-green-700">
                      Listas de substituições e pdf incluídos!
                    </p>
                  </div>
                )}
              </div>

              <div className="lg:w-1/2 lg:pl-4 border-t lg:border-t-0 lg:border-l border-gray-200 pt-4 lg:pt-0 mt-4 lg:mt-0">
                <div className="bg-white border  border-gray-200 rounded-lg p-3 sm:p-4 mb-4">
                  <div className="flex items-center mb-4">
                    <FaLock className="text-green-500 mr-2 text-lg" />
                    <span className="text-sm text-green-600">Pagamento 100% Seguro</span>
                  </div>
                  <GatewayPagamento
                    amount={amount}
                    handlePaymentSuccess={handlePaymentSuccess}
                    handlePaymentWaiting={handlePaymentWaiting}
                    addOrderBump={true}
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <p className="text-center text-sm text-gray-600">
                Dieta gerada após o pagamento. Por favor, volte ao site para acessar sua dieta.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}