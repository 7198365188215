import React, { useState, useEffect } from "react";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from 'react-router-dom';
import { IMaskInput } from "react-imask";
import Apospagamento from '../apospagamento/instrucoesPg';
import { motion } from "framer-motion";
import { ChevronDown, User, Calendar, Target,Coffee, Bookmark, UserCheck, Moon } from "react-feather";
import '../ChatContainer/ChatContainer.css'


const ChatContainer = ({ sendRequest, sendRequestTreinoF, sendRequestTreinoM }) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    weight: "",
    height: "",
    age: "",
    goal: "",
    chocolate: "",
    treino: "",
    calorias: "",
    exercicio: "",
    genero: "",
  });
  const [selectedFoods, setSelectedFoods] = useState({
    cafe: [],
    almoco: [],
    lanche: [],
    janta: [],
  });
  const [error, setError] = useState("");
  const { retornaTicket, adicionarTicketUsado, salvarDados, retirarTicket, adicionarPrompt } = UserAuth();
  const [userTickets, setUserTickets] = useState(null);

  const foodOptions = {
    cafe: [
      { img: "🍞", food: "Pão + Queijo", foodd: "Pão com Queijo" },
      { img: "🥛", food: "Iogurte", foodd: "Iogurte" },
      { img: "🍚", food: "Cuscuz", foodd: "Cuscuz" },
      { img: "☕", food: "Café", foodd: "Café" },
      { img: "🥖", food: "Pão + Ovo", foodd: "Pão com Ovo mexido ou omelete" },
      { img: "🥙", food: "Tapioca + Frango", foodd: "Tapioca com recheio de Frango" },
      { img: "☕", food: "Café + Leite", foodd: "Café com Leite" },
      { img: "🥞", food: "Crepioca + Queijo", foodd: "Crepioca com recheio de Queijo" },
      { img: "🍎", food: "Fruta", foodd: "Fruta" },
      { img: "🧀", food: "Pão de Queijo", foodd: "Pão de Queijo" }
    ],
    
    almoco: [
      { img: "🍗", food: "Frango", foodd: "Frango" },
      { img: "🥩", food: "Patinho", foodd: "Patinho" },
      { img: "🍖", food: "Carne Moida", foodd: "Carne Moida" },
      { img: "🥔", food: "Mandioca", foodd: "Mandioca" },
      { img: "🐖", food: "Carne-Porco", foodd: "Carne de Porco" },
      { img: "🍠", food: "Batata-Doce", foodd: "Batata Doce" },
      { img: "🐟", food: "Tilápia", foodd: "Tilápia" },
      { img: "🍚", food: "Arroz", foodd: "Arroz" },
      { img: "🥣", food: "Feijão", foodd: "Feijão" },
      { img: "🥗", food: "Salada", foodd: "Salada de alface" },
      { img: "🍝", food: "Macarrão", foodd: "Macarrão" },
      { img: "🥚", food: "Ovo", foodd: "Ovo" },
      { img: "🍚", food: "Cuscuz", foodd: "Cuscuz" },
      { img: "🥔", food: "Batata", foodd: "Batata Inglesa" },
    ],
    
    lanche: [
      { img: "🥤", food: "Whey", foodd: "Shake de Whey" },
      { img: "🍎", food: "Fruta", foodd: "Fruta" },
      { img: "🍚", food: "Cuscuz", foodd: "Cuscuz com ovo" },
      { img: "🥖", food: "Pão + Ovo", foodd: "Pão com Ovo mexido ou omelete" },
      { img: "🥙", food: "Tapioca + Frango", foodd: "Tapioca com recheio de Frango" },
      { img: "🥞", food: "Crepioca + Queijo", foodd: "Crepioca com recheio de Queijo" },
      { img: "🌮", food: "Rap10 + Frango", foodd: "Rap10 com recheio de Frango" },
      { img: "🥚", food: "Ovo", foodd: "Ovo" },
      { img: "🥪", food: "Sanduíche Frango", foodd: "Sanduíche Frango" },
      { img: "🥪", food: "Sanduíche de Peru", foodd: "Sanduíche de Peru" },
    ],
    
    janta: [
      { img: "🍗", food: "Frango", foodd: "Frango" },
      { img: "🥩", food: "Patinho", foodd: "Patinho" },
      { img: "🍖", food: "Carne Moida", foodd: "Carne Moida" },
      { img: "🥔", food: "Mandioca", foodd: "Mandioca" },
      { img: "🐖", food: "Carne-Porco", foodd: "Carne de Porco" },
      { img: "🍠", food: "Batata-Doce", foodd: "Batata Doce" },
      { img: "🐟", food: "Tilápia", foodd: "Tilápia" },
      { img: "🍚", food: "Arroz", foodd: "Arroz" },
      { img: "🥣", food: "Feijão", foodd: "Feijão" },
      { img: "🥗", food: "Salada", foodd: "Salada de alface" },
      { img: "🍝", food: "Macarrão", foodd: "Macarrão" },
      { img: "🥚", food: "Ovo", foodd: "Ovo" },
      { img: "🍚", food: "Cuscuz", foodd: "Cuscuz" },
      { img: "🥔", food: "Batata", foodd: "Batata Inglesa" },
    ],
    
  };

  useEffect(() => {
    retornaTicket()
      .then((tickets) => {
        setUserTickets(tickets !== null ? tickets : "Nenhum ticket disponível");
      })
      .catch(() => {
        setUserTickets("Erro ao obter os tickets");
      });
  }, [retornaTicket]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFoodSelection = (meal, food) => {
    setSelectedFoods((prev) => {
      const updatedMeal = prev[meal].includes(food)
        ? prev[meal].filter((item) => item !== food)
        : [...prev[meal], food];
      return { ...prev, [meal]: updatedMeal };
    });
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    document.body.classList.add("scroll-lock");

    try {
      const data = generateDietPlan();
      if (userTickets === "DietaTreino" || userTickets === "DietaNormal") {
        if (formData.treino !== 'Não' && userTickets === "DietaTreino") {
          formData.genero === 'Feminino' ? sendRequestTreinoF(data, formData.treino) : sendRequestTreinoM(data, formData.treino);
        } else {
          sendRequest(data);
        }
      }
      resetForm();
      await retirarTicket();
      adicionarTicketUsado();
      navigate('/carregando');
    } catch (err) {
      setError('Estamos com bastante requisições! Coloque suas informações novamente para montar sua dieta');
    } finally {
      setIsSubmitting(false);
      document.body.classList.remove('scroll-lock');
    }
  };

  const handleSubmitSemTicket = async () => {
    if (!validateForm()) return;

    const data = generateDietPlan();
    await adicionarPrompt(data, formData.treino, formData.genero);
    const imc = formData.weight / (formData.height * formData.height);
    await salvarDados(formData.weight, formData.height, parseInt(imc));
    navigate('/planos');
  };

  const validateForm = () => {
    if (!formData.weight || !formData.height) {
      setError("Por favor, coloque os campos de altura e peso apenas com números.");
      return false;
    }
    if (!formData.goal) {
      setError("Por favor, selecione o seu Objetivo");
      return false;
    }
    if (!formData.calorias) {
      setError("Por favor, selecione as Calorias");
      return false;
    }
    if (!formData.genero) {
      setError("Por favor, selecione o seu de Gênero");
      return false;
    }
    if (Object.values(selectedFoods).some(meal => meal.length === 0)) {
      setError("Por favor, selecione seus alimentos");
      return false;
    }

    return true;
  };

  const generateDietPlan = () => {
    const HomensTMB = parseInt((10 * formData.weight + 6.25 * (formData.height * 100) - 5 * formData.age + 5) * formData.exercicio);
    const MulheresTMB = parseInt((10 * formData.weight + 6.25 * (formData.height * 100) - 5 * formData.age - 161) * formData.exercicio);

    const calcularCalorias = () => {
      const baseTMB = formData.genero === 'Masculino' ? HomensTMB : MulheresTMB;
      switch (formData.goal) {
        case "Emagrecimento com facilidade":
          return `${baseTMB - 700} a ${baseTMB - 500}`;
        case "Emagrecimento com facilidade + ganho de massa":
          return `${baseTMB - 400} a ${baseTMB - 200}`;
        case "Jejum Intermitente":
          return `${baseTMB - 600} a ${baseTMB - 500}`;
        case "Definição Muscular":
          return `${baseTMB - 300} a ${baseTMB - 200}`;
        case "Bulking":
          return `${baseTMB + 500} a ${baseTMB + 700}`;
        case "Definição e ganho de Massa Muscular":
          return `${baseTMB + 200} a ${baseTMB + 400}`;
        default:
          return `${baseTMB - 100} a ${baseTMB + 100}`;
      }
    };
    const metaCalorias = formData.calorias === 'Não' ? calcularCalorias(formData.goal, formData.genero) : formData.calorias;
    const [min, max] = metaCalorias.split(" a ").map(Number);
    const valores = [];
    for (let i = min; i <= max; i += 100) {
    valores.push(i);
    }

// 3. Sortear um valor da lista
const sorteado = valores[Math.floor(Math.random() * valores.length)];

   
    return `Crie um plano de dieta exclusivo para atingir o objetivo de ${formData.goal} calorias para uma pessoa de ${formData.weight}kg e tem ${formData.height}m , seguindo estas diretrizes:
      1. Apresente o total de calorias ao final de cada opção e não coloque as calorias de cada alimentos.
    2. Utilize apenas os alimentos listados:
        - Café da manhã: Meta 20% de ${sorteado} kcal: ${selectedFoods.cafe}
       - Lanche da manhã: Meta 15% de ${sorteado} kcal: ${selectedFoods.lanche}
       - Almoço: Meta 25% de ${sorteado} kcal: ${selectedFoods.almoco}
       - Lanche da tarde: Meta 15% de ${sorteado} kcal: ${selectedFoods.lanche}
       - Jantar: Meta 25% de ${sorteado} kcal: ${selectedFoods.janta}
       Adicione um ${formData.chocolate} no lanche 2 vezes na semana no maximo.
        3. Certifique-se de que o conjunto de refeições (café da manha, lanche da manha, almoço, lanche da tarde e janta) da opção tenha ${sorteado} kcal.
   3. Para o almoço, inclua sempre combinações de carboidrato, proteína e legumes mas não se limite a colocar so esses alimentos.   
     5. Para o almoço e janta, inclua sempre que possível combinações de 60% de carboidratos, 30% proteínas e 10% legumes. Caso Arroz e Feijão sejam mencionados coloque em todas as opções das respectivas do almoço e janta. Seja flexível na quantidade das porções 
    6. Apresente as opções de forma clara, com cada alimento em uma linha separada para facilitar a visualização. 
    Ajuste as quantidades dos alimentos em gramas para garantir que cada refeição corresponda ao valor calórico ideal, modificando as quantidades de gramas conforme necessário. 
    Finalize o plano sem adicionar mensagens ou observações adicionais após a última opção de jantar.`
  };

  const resetForm = () => {
    setFormData({
      weight: "",
      height: "",
      age: "",
      goal: "",
      chocolate: "",
      treino: "",
      calorias: "",
      exercicio: "",
      genero: "",
    });
    setSelectedFoods({
      cafe: [],
      almoco: [],
      lanche: [],
      janta: [],
    });
    setError("");
  };

  return (
    <div className="max-w-6xl mx-auto p-8 bg-gradient-to-br from-white to-gray-10 rounded-2xl shadow-2xl" style={{ border: '1px solid #D9D8D8' }}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="space-y-8">
          <InputSection
            icon={<User className="text-green-500" />}
            label="Informações Pessoais"
            inputs={[
              { name: "weight", placeholder: "Peso (kg)", mask: "000" },
              { name: "height", placeholder: "Altura (m)", mask: "0.00" },
              { name: "age", placeholder: "Idade", mask: "00" },
            ]}
            values={formData}
            onChange={handleInputChange}
          />

<SelectSection
  icon={<Target className="text-green-500" />}
  label="Objetivo"
  name="goal"
  value={formData.goal}
  onChange={handleInputChange}
  options={[
    { value: "", label: "Qual é seu objetivo?" },
    { value: "Emagrecimento com facilidade", label: "Emagrecer" },
    { value: "Bulking", label: "Ganho Massa Muscular" },
    { value: "Definição e ganho de Massa Muscular", label: "Definição + Massa Muscular" },
    { value: "Definição Muscular", label: "Definição" },
    { value: "Emagrecimento com facilidade + ganho de massa", label: "Emagrecer + Massa Muscular" },
  ]}
  className="bg-white text-black"
/>

          <SelectSection
            icon={<Calendar className="text-green-500" />}
            label="Calorias"
            name="calorias"
            value={formData.calorias}
            onChange={handleInputChange}
            options={[
              { value: "", label: "Calorias desejadas" },
              { value: "Não", label: "Não sei dizer" },
              { value: "1200 a 1500", label: "1200 a 1500 calorias" },
              { value: "1600 a 1900", label: "1600 a 1900 calorias" },
              { value: "2000 a 2300", label: "2000 a 2300 calorias" },
              { value: "2400 a 2700", label: "2400 a 2700 calorias" },
              { value: "2800 a 3100", label: "2800 a 3100 calorias" },
              { value: "3200 a 3500", label: "3200 a 3500 calorias" },
            ]}
          />

         
        </div>

        <div className="space-y-8">
          <FoodSelectionSection
            icon={<Coffee className="text-green-500" />}
            label="Café da Manhã"
            options={foodOptions.cafe}
            selected={selectedFoods.cafe}
            onSelect={(food) => handleFoodSelection('cafe', food)}
          />

          <FoodSelectionSection
            icon={<Bookmark className="text-green-500" />}
            label="Almoço"
            options={foodOptions.almoco}
            selected={selectedFoods.almoco}
            onSelect={(food) => handleFoodSelection('almoco', food)}
          />

          <FoodSelectionSection
            icon={<UserCheck className="text-green-500" />}
            label="Lanche"
            options={foodOptions.lanche}
            selected={selectedFoods.lanche}
            onSelect={(food) => handleFoodSelection('lanche', food)}
          />

          <FoodSelectionSection
            icon={<Moon className="text-green-500" />}
            label="Jantar"
            options={foodOptions.janta}
            selected={selectedFoods.janta}
            onSelect={(food) => handleFoodSelection('janta', food)}
          />
        </div>
      </div>

      <div className="mt-12 space-y-8">
        <div className="flex justify-center space-x-4">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={`px-8 py-3 rounded-full text-lg font-semibold transition-colors duration-300 ${
              formData.genero === 'Masculino' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setFormData(prev => ({ ...prev, genero: 'Masculino' }))}
          >
            Masculino
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={`px-8 py-3 rounded-full text-lg font-semibold transition-colors duration-300 ${
              formData.genero === 'Feminino' ? 'bg-pink-500 text-white' : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => setFormData(prev => ({ ...prev, genero: 'Feminino' }))}
          >
            Feminino
          </motion.button>
        </div>

        {error && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-md" role="alert">
            <p className="font-bold">Erro</p>
            <p>{error}</p>
          </div>
        )}

{userTickets === 0 || userTickets === undefined ? (
  <div className="text-center space-y-4">
    <Apospagamento />
    <motion.button
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      className="w-full bg-green-600 text-white border border-green-300 py-3 px-6 rounded-full text-base font-medium shadow-sm transition-all duration-300"
      onClick={handleSubmitSemTicket}
    >
      MONTAR MINHA DIETA
    </motion.button>
  </div>
        ) : (
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={`w-full py-4 px-6 rounded-full text-lg font-bold shadow-lg transition-all duration-300 ${
              isSubmitting || (userTickets !== 'DietaTreino' && userTickets !== 'DietaNormal')
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-gradient-to-r from-green-400 to-blue-500 text-white hover:from-green-500 hover:to-blue-600'
            }`}
            disabled={isSubmitting || (userTickets !== 'DietaTreino' && userTickets !== 'DietaNormal')}
            onClick={handleSubmit}
          >
            {isSubmitting ? 'Processando...' : 'MONTAR MINHA DIETA'}
          </motion.button>
        )}
      </div>
    </div>
  );
};

const InputSection = ({ icon, label, inputs, values, onChange }) => (
  <div className="space-y-3">
    <div className="flex items-center space-x-2 text-xl font-semibold text-gray-700">
      {icon}
      <span>{label}</span>
    </div>
    <div className="grid grid-cols-1 gap-4">
      {inputs.map((input) => (
        <div key={input.name} className="relative">
          <IMaskInput
            mask={input.mask}
            type="text"
            name={input.name}
            value={values[input.name]}
            onChange={onChange}
            placeholder={input.placeholder}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-300"
          />
        </div>
      ))}
    </div>
  </div>
);

const SelectSection = ({ icon, label, name, value, onChange, options }) => (
  <div className="space-y-3">
    <div className="flex items-center space-x-2 text-xl font-semibold text-gray-700">
      {icon}
      <span>{label}</span>
    </div>
    <div className="relative">
      <select
        name={name}
        value={value}
        onChange={onChange}
        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent appearance-none transition-all duration-300"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
    </div>
  </div>
);

const FoodSelectionSection = ({ icon, label, options, selected, onSelect }) => (
  <div className="space-y-3">
    <div className="flex items-center space-x-2 text-xl font-semibold text-gray-700">
      {icon}
      <span>{label}</span>
    </div>
    <div className="grid grid-cols-2 gap-2">
      {options.map((food) => (
        <motion.button
          key={food.food}
          
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className={`p-3 rounded-lg text-sm font-medium transition-all duration-300 ${
            selected.includes(food.foodd)
              ? 'bg-green-500 text-white shadow-md'
              : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
          }`}
          onClick={() => onSelect(food.foodd)}
        >
          {food.food}
          {food.img}

        </motion.button>
      ))}
    </div>
  </div>
);

export default ChatContainer;